// extracted by mini-css-extract-plugin
export var about = "about-module--about--Cpp23";
export var banner = "about-module--banner--1sfTW";
export var describe = "about-module--describe--58SHL";
export var contain = "about-module--contain--cbmnk";
export var all = "about-module--all--TTGxI";
export var example = "about-module--example--8LxBs";
export var aboutUs = "about-module--aboutUs--brZto";
export var left = "about-module--left--i8B7y";
export var des = "about-module--des--13pXv";
export var des_active = "about-module--des_active--xICYL";
export var right = "about-module--right--MKYNu";
export var version = "about-module--version--D7lxb";
export var video = "about-module--video--l6XuE";
export var abs = "about-module--abs--7MWL0";
export var fixed = "about-module--fixed--lcd5o";
export var video_content = "about-module--video_content--YNg2r";
export var subm = "about-module--subm--wC46U";
export var main = "about-module--main--jeCVu";
export var btn = "about-module--btn--G+923";
export var core = "about-module--core--lSZm5";
export var rowone = "about-module--rowone--mbWDl";
export var as = "about-module--as--Of4Sx";