import React, { useState ,useEffect,useRef} from "react"
import Layout from "../components/Layout"
import * as styles from '../styles/about.module.scss'
import { Link } from "gatsby"
import email from '../images/footer/about-email.png'
import aboutMv from '../vedio/RCS.mp4'
import left from '../images/about/left.png'
import network from '../images/about/network.png'
import person from '../images/about/person.png'
import service from '../images/about/service.png'
import newVideo from '../vedio/newContact.mp4'
import AnimationLoops from '../components/animationLoops'

import Email from '../components/email'

import Fade from 'react-reveal/Fade';
const About = ({ location }) => {
    const videoDom = useRef(null)
    const [position,setPosition] = useState(false)
    useEffect(()=>{
        window.addEventListener('scroll',handleScroll)
    })
    const handleScroll = (e)=>{
        let scrollTop = (e.srcElement ? e?.srcElement.documentElement.scrollTop : false) || window.pageYOffset || (e.srcElement ? e.srcElement.body.scrollTop : 0);
        if(scrollTop>=videoDom.current?.offsetTop){
            setPosition(true)
        }else{
            setPosition(false)
        }
        
    }
    const [checkBg, setBg] = useState(0)
    const bgList = [person, service, network]

    const turnPre = () => {
        checkBg === 0 ? setBg(2) : setBg(checkBg - 1)
    }
    const turnNext = () => {
        checkBg === 2 ? setBg(0) : setBg(checkBg + 1)
    }
    function turnCheck(e) {
        const index = parseInt(e.target.dataset.index);
        setBg(index)
    }
    let screenWidth = typeof window !== "undefined" ? window.screen.width :""
    return (
        <Layout >
            <section className={styles.about}>
                <Email />
                <div className={styles.banner}>
                    <video src={aboutMv} muted="muted" autoPlay="autoPlay" loop ></video>
                    <Link to="#describe">
                        <AnimationLoops />
                    </Link>
                    <div>
                        <div>
                            <Fade bottom cascade={screenWidth <= 675 ? false : true} big duration={800}>
                                 Resilience Corporate 
                            </Fade>
                        </div>
                        <div>
                            <Fade bottom cascade={screenWidth <= 675 ? false : true} big duration={800} delay={200}>
                            Services
                            </Fade>
                        </div>
                    </div>
                </div>
                <div className={styles.describe} id='describe'>
                    <div className={styles.contain}>
                        <p>Resilience Corporate Services is a Canadian company with international members, teams, and clients across the globe</p>
                        
                    </div>
                </div>
                <div className={styles.aboutUs}>
                    <div className={styles.contain}>
                        <ul className={styles.left}>
                            <li>
                                <Fade bottom delay={500}>
                                    About us
                                </Fade>
                            </li>
                            {/* <li>We can help you reach more customers in more places, source labor from a broader market, remove many of the overhead costs and waste that plagued businesses, and more.</li> */}
                            {/* <li>At RCS we are motivated and determined to improve our clients and partners in significant ways. We work on organisational challenges that transform businesses, government partners.</li>
                            <li>We offer a broad consulting capability that can take you from strategy and design to transformational change. We build your organisational capability in the process so that what we achieve together, the difference we make together, can be sustained into the future.</li>
                            <li>Resilience Corporate Services is a remote company with a network of international staff and partners to support our global clients.  </li>
                            <li>We at RCS believe that operating as a remote company has enabled us to build and maintain an inclusive and diverse global team with the talent and ability to support any client, anywhere, at any time.</li> */}
                            <li>Resilience Corporate Services is a remote company with a network of international staff and partners to support and improve our global clients and partners in significant ways.</li>
                            <li>We offer a broad consulting capability that can take you from strategy and design to transformational change. We build your organizational capability in the process so that what we achieve together, the difference we make together, can be sustained into the future.</li>
                            <li>We at Resilience Corporate Services believe that operating as a remote company has enabled us to build and maintain an inclusive and diverse global team with the talent and ability to support any client, anywhere, at any time.</li>
                            {/* <li data-index='0' className={checkBg === 0 ? `${styles.des} ${styles.des_active}` : `${styles.des}`} onClick={turnCheck}>
                                <div data-index='0' >
                                    <span data-index='0'></span><span data-index='0'>Personal approach</span>
                                </div>
                                <p data-index='0'>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo</p>
                            </li>
                            <li data-index='1' className={checkBg === 1 ? `${styles.des} ${styles.des_active}` : `${styles.des}`} onClick={turnCheck}>
                                <div data-index='1' >
                                    <span data-index='1'></span><span data-index='1'>Result-oriented service</span>
                                </div>
                                <p data-index='1'>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo</p>
                            </li>
                            <li data-index='2' className={checkBg === 2 ? `${styles.des} ${styles.des_active}` : `${styles.des}`} onClick={turnCheck}>
                                <div data-index='2'>
                                    <span data-index='2'></span><span data-index='2'>International network</span>
                                </div>
                                <p data-index='2'>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo</p>
                            </li> */}
                        </ul>
                        <div className={styles.right}>
                            <div></div>
                            <div>
                                <div>
                                    <span onClick={turnPre}>
                                        <img src={left} alt=""  />
                                    </span>
                                    <span onClick={turnNext}>
                                        <img src={left} alt=""  />
                                    </span>
                                </div>
                                <Fade top delay={500}>
                                    <div style={{ background: `url(${bgList[checkBg]}) no-repeat` }}></div>
                                </Fade>

                            </div>
                        </div>
                    </div>

                </div>
                <div className={styles.version}>
                    <div className={styles.contain}>
                        <span>
                            <Fade bottom delay={300}>
                                Vision
                            </Fade>
                        </span>
                        <div>
                            <span></span><span>Resilience's flexible partnership models allow us to be responsive to your unique needs.</span>
                        </div>
                    </div>
                </div>
                <div className={styles.video} id="video" ref={videoDom}>
                    <video playsInline className={position?`${styles.fixed}`:`${styles.abs}`} src={newVideo} muted="muted" playsInline autoPlay="autoPlay" loop></video>
                    <div className={styles.video_content}>
                        <h3>Email</h3>
                        <p>Please feel free to contact us by email.</p>
                        <div><img src={email} alt="" /><span>info@resiliencecorporateservices.com</span></div>
                    </div>
                </div>
                <div className={styles.subm} id="subm">
                    <div className={styles.main}>
                        <div className={styles.left}>
                            <Fade bottom delay={500}>
                                <h2>Get in touch</h2>
                            </Fade>
                            <Fade bottom delay={700}>
                                <span>Have a project you need help with? Connect with us.</span>
                            </Fade>
                        </div>
                        <form action="">
                            <div>
                                <label htmlFor="name">Name<span>*</span> :</label>
                                <input type="text" name="name" id="name" />
                            </div>
                            <div>
                                <label htmlFor="email">Email<span>*</span> :</label>
                                <input type="text" name="email" id="email" />
                            </div>
                            <div>
                                <label htmlFor="subject">Subject<span>*</span> :</label>
                                <input type="text" name="subject" id="subject" />
                            </div>
                            <div>
                                <label htmlFor="message">Message<span>*</span> :</label>
                                <textarea name="message" id="message" cols="30" rows="10"></textarea>
                            </div>
                            <div className={styles.btn}>SUBMIT</div>
                        </form>
                    </div>
                </div>
            </section>
        </Layout >
    )
}

export default About